<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
import { watch } from 'vue';
import { TagDto, TagService } from '@/api';
import Tag from '@/components/tags/Tag.vue';
import { VAutocomplete } from 'vuetify/lib';

interface TagSelectorProps {
  filterMode?: boolean;
  returnObject?: boolean;
}
const props = defineProps({
  filterMode: { type: Boolean, default: false },
  returnObject: { type: Boolean, default: true },
  value: null
});

type TagSelectorEmits = {
  (event: 'input', tags: TagDto[] | string[] | null): void
}
const emits = defineEmits(["input"]);

const { value: selectedTags } = __MACROS_useVModel(["value", undefined, "input"]);

let tags = _ref<TagDto[]>([]);
let loading = _ref(false);
const searchString = _ref('');
const tagSelector = _ref<{ blur:() => void } | null>(null);

const appendIcon = _computed(() => (selectedTags.value === null || selectedTags.value?.length ? 'icon-dismiss' : ''));
const mustReturnObject = _computed(() => (props.filterMode ? false : props.returnObject !== false));
const isFilterMode = _computed(() => props.filterMode);
const innerIcon = _computed(() => (props.filterMode ? 'icon-tag' : ''));

/**
 * Get different item icon based on content value
 * @param hasValue true if not empty
 */
const customItemIcon = (hasValue: boolean) => (hasValue ? 'icon-check-square-fill' : 'icon-check-square-outline-empty');

/**
 * Close v-autocomplete dropdown
 */
const closeMenu = () => {
  tagSelector.value?.blur();
};

/**
 * Clear selected tags & fetched data
 */
const clear = () => {
  tags.value = [];
  selectedTags.value = [];
  emits('input', selectedTags.value);
  closeMenu();
};

/**
 * Fetch tag data based on search string
 */
const fetchTags = async () => {
  loading.value = true;
  const { data } = await TagService.getAllTag({ textSearch: searchString.value, itemsPerPage: -1 });
  tags.value = data;
  loading.value = false;
};

/**
 * Helper function for v-autocomplete item-value
 * @param item tag item
 */
const getItemValue = (item: TagDto): TagDto | string => (isFilterMode.value ? item.id : item);

/**
 * Check if tooltip should be shown for given item
 * @param item tag item name
 */
const shouldShowTooltip = (item: string) => item.length >= 20;

watch(() => searchString.value, () => {
  fetchTags();
});
</script>

<template lang="pug">
v-autocomplete(
  :class="{'tag-selector': !isFilterMode, 'dashboard-tag-selector': isFilterMode}"
  ref="tagSelector"
  v-bind='$attrs'
  v-model="selectedTags"
  v-on='$listeners'
  :loading="loading"
  :items="tags"
  :search-input.sync="searchString"
  :return-object="mustReturnObject"
  :append-icon="appendIcon"
  :prepend-inner-icon="innerIcon"
  @change="searchString=''"
  @click:append="clear"
  :placeholder="$t('tags.placeholder')"
  label=""
  :item-value="getItemValue"
  item-text="name"
  data-cy="tag-selector"
  cache-items
  dense
  outlined
  hide-details
  hide-no-data
  multiple)

  template(v-slot:item="{ item, attrs }")
    div(data-cy="tag-selector-item")
      v-icon.ml-2.mr-1 {{ customItemIcon(attrs.inputValue) }}
      Tag.my-3( :tag="item" :key="item.id" small) {{ item.name }}

  template(v-slot:selection="{ item }")
    Tag.my-1( v-if="!isFilterMode" :tag="item" :key="item.id" small) {{ item.name }}
    v-tooltip( v-else-if="shouldShowTooltip(item.name)" top )
      template(v-slot:activator='{ on }')
        Tag.mb-1( v-on="on" :tag="item" :key="item.id" small style="max-width: 165px !important") {{ item.name }}
      span {{ item.name }}
      div.tooltip-arrow-bottom
    Tag.mb-1( v-else :tag="item" :key="item.id" small style="max-width: 165px !important") {{ item.name }})

</template>

<style lang="scss">
.resetLineHeight {
  line-height: initial;
}

.tag-selector {
  .v-select__slot {
    padding: 3px 0px 3px;
  }

  .v-input__append-inner {
    margin-top: 5px !important;
  }
}

.dashboard-tag-selector {
  background-color: var(--v-bgPrimary-base) !important;
  width: 250px;
  margin-top: 10px;

  .v-select__selections {
    margin-top: 4px;
  }

  .v-input__slot {
    opacity: 0.7;
  }

  .v-label {
    margin-top: -2px;

    &.v-label--active {
      margin-top: 0px;
    }
  }

  .v-input__icon {
    &.v-input__icon--prepend-inner {
      margin-top: 2px;
      opacity: 0.5;
    }

    &.v-input__icon--append {
      margin-top: 2px;
      opacity: 0.5;
    }

    &.v-input__icon--clear {
      opacity: 0.5;
    }
  }
}
</style>
